import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { ClipboardModule } from "ngx-clipboard";
import { TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthService } from "./modules/auth/_services/auth.service";
import { environment } from "src/environments/environment";
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
import { SplashScreenModule } from "./_metronic/partials/layout/splash-screen/splash-screen.module";
// #fake-start#
import { FakeAPIService } from "./_fake/fake-api.service";
// #fake-end#

import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { ApiService } from "./services/api.service";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { ToastrModule } from "ngx-toastr";

import xml from "highlight.js/lib/languages/xml";
import json from "highlight.js/lib/languages/json";
import scss from "highlight.js/lib/languages/scss";
import typescript from "highlight.js/lib/languages/typescript";
import { ReportComponent } from "./pages/reports/reports.component";
import { MatTableModule } from "@angular/material/table";
import { LandingComponent } from "./pages/landing/landing.component";
import { ScriptHackComponent } from "./pages/shared/script-hack/script-hack.component";
import { CsvDataService } from "./services/csvdata.service";
import { FooterComponent } from "./modules/deals/footer/footer.component";
import { AddHeaderInterceptor } from "./services/auth.interceptor";
import { TermsOfServiceComponent } from "./pages/terms/terms-of-service.component";
import { TypeformComponent } from "./pages/typeform/typeform.component";

const fbConstant = {
  apiKey: "AIzaSyB5weNo7REucbOHekccxXsTTXqlunOqqrc",
  authDomain: "zeiger-bc762.firebaseapp.com",
  databaseURL: "https://zeiger-bc762.firebaseio.com",
  projectId: "zeiger-bc762",
  storageBucket: "zeiger-bc762.appspot.com",
  messagingSenderId: "158438149831",
  appId: "1:158438149831:web:3e2a6edeb87078a3",
  measurementId: "G-3WRMHGMQ7T",
};

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

export function getHighlightLanguages() {
  return [
    { name: "typescript", func: typescript },
    { name: "scss", func: scss },
    { name: "xml", func: xml },
    { name: "json", func: json },
  ];
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    ScriptHackComponent,
    TermsOfServiceComponent,
    TypeformComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
          passThruUnknownUrl: true,
          dataEncapsulation: false,
        })
      : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    AngularFireModule.initializeApp(fbConstant),
    AngularFirestoreModule,
    AngularFireAuthModule,
    ToastrModule.forRoot(),
    MatTableModule,
  ],
  providers: [
    ApiService,
    CsvDataService,

    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        languages: getHighlightLanguages,
      },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
