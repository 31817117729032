import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
  } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from './api.service';
   
  export class AddHeaderInterceptor implements HttpInterceptor {
     intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // Clone the request to add the new header
      const token = localStorage.getItem('zap-token')
      if (req.url === 'https://api.blockchain.info/mempool/fees' || req.url === 'https://api.ipify.org?format=json&callback=IP') {
        return next.handle(req)
      } else {
        const clonedRequest = req.clone({ headers: req.headers.append('Authorization', `Bearer ${token}`) });

      // Pass the cloned request instead of the original request to the next handle
      return next.handle(clonedRequest);
      }
    }
}
