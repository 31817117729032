// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v723demo3',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'api',
  firebase: {
    apiKey: "AIzaSyB5weNo7REucbOHekccxXsTTXqlunOqqrc",
    authDomain: "zeiger-bc762.firebaseapp.com",
    databaseURL: "https://zeiger-bc762.firebaseio.com",
    projectId: "zeiger-bc762",
    storageBucket: "zeiger-bc762.appspot.com",
    messagingSenderId: "158438149831",
    appId: "1:158438149831:web:3e2a6edeb87078a3",
    measurementId: "G-3WRMHGMQ7T"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
