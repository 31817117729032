<!DOCTYPE html>
<!-- Last Published: Fri Mar 25 2022 10:40:49 GMT+0000 (Coordinated Universal Time) -->
<html>
	<head>
		<meta charset="utf-8" />
 	 
		<meta property="og:type" content="website" />
		<meta content="summary_large_image" name="twitter:card" />
		<meta content="width=device-width, initial-scale=1" name="viewport" />
		<link
         href="https://www.stratosnetwork.com/css/fa.css"
         rel="stylesheet"
         type="text/css"
         />
		<script
         src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js"
         type="text/javascript"
         ></script>
		<script type="text/javascript">
         WebFont.load({  google: {    families: ["Merriweather:300,300italic,400,400italic,700,700italic,900,900italic","Inter:300,regular,500,600,700"]  }});
      </script>
		<!--[if lt IE 9
         ]>
		<script
         src="https://cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv.min.js"
         type="text/javascript"
         ></script
         >
		<![endif]-->
		<link
         href="./img/favicon.png"
         rel="shortcut icon"
         type="image/x-icon"
         />
		<link
         href="./img/favicon.png"
         rel="apple-touch-icon"
         />
	</head>
	<body class="body-3">
		 
 
		<div class="wf-section">
			<div class="wrapper">
            <div class="textpagescontainer w-richtext">
               <h1>Terms of Service
                  <br>
               </h1>
               <h3>Terms and Conditions of Use of Zapruder Affiliates' Services
                  <br>
               </h3>
               <div class="w-richtext">
                   <p>
                       Note: Zapruder Affiliates is a domain owned by Zeiger Inc. Invoices, Tax Documents and other statements may show the parent company Zeiger Inc. 
                   </p>
                  <p>Zeiger Inc Terms and Conditions (the “Agreement”) is a legal agreement between Zeiger Inc and Zeiger Inc’s customer, “Publisher.” By registering as a customer for Zeiger Inc’s “Affiliate Advertising Program,” Publisher confirms it has read, acknowledges and agrees to all the terms and conditions of this Agreement. By accepting this Agreement, Affiliate Operator agrees that Zeiger Inc’s Terms and Conditions, as it exists at any relevant time, shall be applicable to Publisher.
                </p>
                  <h4>Definitions</h4>
                  <p>"Publisher" or “Affiliate Operator” means an individual or a business entity, approved by Zeiger as a participant of the Zeiger Advertising Program, and whose name and address are specified in the Account with Zeiger.
                </p>
                <p>
                    "Advertising Program" means Zeiger Incs’ proprietary web-based advertising platform, accessible at https://www.zapruderaffiliates.com/ or at any other website or mobile application provided by Zeiger.
                </p>
                <p>
                    "Media" means (i) websites, social media groups, accounts or channels owned, controlled or operated by Publisher; and/or (ii) email campaigns, social networks campaigns, search engine advertising campaigns or other campaigns run by Publisher in compliance with this Agreement.

                </p>
                <p>
                    "Tracking URL" means a unique URL which directs Leads and New Customers to the Advertiser's destination and serves to attribute a particular Lead or New Customer to Advertiser according to this Agreement.
                </p>

                <p>
                    "Creatives" means graphic and textual materials and/or other creative materials promoting Advertiser, its brand(s), products and/or its services, which Publisher may display on Media.
                </p>
                <p>
                    "Lead" means a potential customer introduced by Publisher to Advertiser who has performed certain actions (e.g. filled-out a form or an opt-in email address) specified in the relevant Offer and who is seeking to legitimately use advertised products or services.
                </p>
                <p>
                    "New Customer" means an individual, an internet user without a prior or existing account with Advertiser, who (i) is permitted under applicable law to use the services offered by Advertiser, (ii) during the term of the applicable Offer accesses Advertiser websites or other destinations specified in the applicable Offer after being directed through Publisher's Tracking URL from Publisher's Media, (iii) completes the applicable registration process, (iv) agrees and accepts Advertiser's terms of use and/or other agreements, (v) opens a new user account with Advertiser (after such user's identity and age have been verified, and such user has been accepted as a customer by Advertiser), (vi) makes the required minimum deposit in such account and/or performs other actions set out in the applicable Offer, and (vii) such account and/or payment has not been canceled, terminated, refunded or charged back; provided other applicable terms, conditions and requirements of this Agreement and the relevant Offer have been met.
                </p>
                <p>
                    "Remuneration Model" means a specific Remuneration payment mechanism (CPA, CPL, Revenue Share or their combination) as stated in the Offer and Publisher's Account in regard to each Offer accepted by Publisher.

                </p>
                <p>
                    "CPA" means cost per acquisition of New Customer.

                </p>
                <p>
                    "CPL" means cost per Lead, a pricing method when Publisher is paid a one-time fixed fee per Lead.

                </p>
                <p>
                    "Revenue Share" means a Remuneration Model when Zeiger pays Publisher a share of net revenue earned and actually received by Advertiser resulted from a New Customer, subject to all requirements, restrictions and provisions of this Agreement and the relevant Offer.

                </p>
                <p>
                    "Hybrid" means a Remuneration Model that combines features of CPA and Revenue Share pricing methods.
                </p>
                <p>
                    "Marks" means trademarks, service marks, brand names and other designations of Advertiser or of its products, goods or services.
                </p>
                <h4>
                    APPLICATION
                </h4>
                <p>
                    This Agreement starts upon creation of Affiliate Operator’s Zeiger account; Affiliate Operator shall ensure that information provided at or after registration is always accurate, up to date, and truthful.
                </p>
                <h4>USAGE</h4>
                <p>
                    The applicant will create a username and password, “Login Details,” to enter Zeiger Inc’s secure affiliate account interface. From Zeiger Inc’s website, Affiliate Operator will be able to receive reports detailing Affiliate Operator’s commissions; Affiliate Operator must not disclose their Login Details to anyone nor allow anyone else to use them. Affiliate Operator is responsible for the security of their Login Details; Everyone who identifies themselves by entering correct Login Details is assumed by Zeiger Inc to be the Affiliate Operator subject to this agreement and all transactions where Login Details have been entered correctly will be regarded as valid and attributed to the Affiliate Operator of that account; It is Affiliate Operator’s sole responsibility to ensure that at all times it complies with the laws that govern the Affiliate Operator’s activities and that Affiliate Operator has the complete legal right to participate in the Affiliate Advertising Program; It is Affiliate Operator’s responsibility to follow all applicable intellectual property and other laws that pertain to their site. Affiliate Operator must have express permission to use any copyrighted material, whether it be a writing, an image, or any other copyrightable work. Zeiger Inc will not be responsible for Affiliate Operator’s usage of another’s copyrighted material or other intellectual property in violation of the law or any third party rights.

                </p>
                <h4>PAYMENT</h4>
                <p>
                    Zeiger Inc will only pay commissions for new signups successfully tracked by its tracking system and associated with Affiliate Operator’s unique tracking URLs. No payment will be made if a new signup cannot be tracked by Zeiger Inc’s systems; Commissions from Zeiger Inc to Affiliate Operator will typically be made between the 15th and the 30th of the following month; Zeiger Inc collects its payments from Partners, and it is not responsible for any delay of payments to Affiliate Operator if such delay is caused by any delay of payment by such Partners. In the event of such delays Zeiger Inc will inform the Affiliate Operator of the delay. All internal currency conversions will be performed based on FX rates of Zeiger Inc’s bank at the time of transaction.

                </p>
                <p>
                    Advertisers may change any payout rate or cancel the Offer, in whole or in part, at their sole discretion, which may lead to the withholding or adjustments of Remuneration payments;

                </p>
                <p>
                    Zeiger Inc collects its payments from Advertiser, and it is not responsible for any delay of Remuneration payments to Publisher if such delay is caused by any delay of payment by Advertiser;

                </p>
                <p>
                    For the same reason, Zeiger Inc will make payments of Remuneration to Publishers out of funds actually collected by Zeiger from Advertiser who initiated the relevant Offer; Zeiger shall have no payment obligation to Publisher where Advertiser has not remitted sufficient payments to cover the amounts otherwise due to Publisher; and Publisher's sole and exclusive remedy shall be the right to pursue any and all legal remedies directly against any Advertiser that has not made funds available to pay monies due to Publisher for Leads or New Customers in connection with the applicable Offer;

                </p>
                <p>
                    If any Advertiser whose Offer has been executed by Publisher defaults on payment to Zeiger, and Zeiger has paid Remuneration related to such Offer, Zeiger may withhold any further payment for equal amount.
 
                </p>
                <p>
                    If Publisher wishes to dispute any payment made or withheld under this Agreement, Publisher must notify Zeiger in writing within five (5) days of any such payment. Failure to notify Zeiger within the prescribed time limit shall be deemed as an irrevocable acknowledgment of an agreement with the balance due for the period indicated.

                </p>
                <h4>
                    TERMINATION
                </h4>
                <p>
                    Either party may end this Agreement AT ANY TIME, with or without cause, by giving the other party written notice. Written notice can be in the form of mail or email. In addition, this Agreement will terminate immediately upon any breach of this Agreement by the Affiliate Operator; Zeiger Inc reserves the right to terminate this Agreement and Affiliate Operator’s participation in the Affiliate Advertising Program immediately and without notice to Affiliate Operator should Affiliate Operator commit fraud in its use of Zeiger Inc’s Affiliate Advertising Program or in the event of abuse of this program in any way. If such fraud or abuse is detected, Zeiger Inc shall not be liable for any commissions for such fraudulent sales.

                </p>
                <h4>
                    LIMITATIONS OF LIABILITY
                </h4>
                <p>
                    Zeiger Inc will not be liable to Affiliate Operator with respect to any subject matter of this Agreement under any contract, negligence, tort, strict liability, or other legal or equitable theory for any indirect, incidental, consequential, special or exemplary damages (including, without limitation, loss of revenue or goodwill or anticipated profits or lost business), even if Zeiger Inc has been advised of the possibility of such damages. Zeiger’ cumulative liability to publisher from all causes of action and all theories of liability will be limited to and will not exceed the amounts paid to publisher by Zeiger during the month immediately prior to such claim.

                </p>
                <h4> INDEMNIFICATION</h4>
                <p>
                    Affiliate Operator hereby agrees to indemnify and hold harmless Zeiger Inc, and its subsidiaries and affiliates, and their directors, officers, employees, agents, shareholders, partners, members, and other owners, against any and all claims, actions, demands, liabilities, losses, damages, judgments, settlements, costs, and expenses (including reasonable attorneys' fees) (any or all of the foregoing hereinafter referred to as "Losses") insofar as such Losses (or actions in respect thereof) arise out of or are based on (i) any claim that Zeiger Inc’s use of the affiliate trademarks infringes on any trademark, trade name, service mark, copyright, license, intellectual property, or other proprietary right of any third party, (ii) any misrepresentation of a representation or warranty or breach of a covenant and agreement made by the Affiliate Operator herein, or (iii) any claim related to Affiliate Operator’s site, including, without limitation, content therein not attributable to Zeiger Inc.

                </p>
                <h4>NON-EXCLUSIVITY</h4>
                <p>
                    This Agreement does not create an exclusive relationship between Zeiger Inc and the Affiliate Operator. Affiliate Operator is free to work with similar affiliate program providers in any category. This agreement imposes no restrictions or requirements on Zeiger Inc to work or not work with any individual or company as it chooses.

                </p>
                <h4>ADVERTISER TERMS OF SERVICE</h4>
                <p>
                    A violation of any upstream Advertiser TOSs would also constitute a violation of these terms and conditions. In the event the Affiliate Operator violates the TOSs for a brand which it is advertising for, Zeiger Inc assumes no liability for damages caused to the brand from the Affiliate Operator. By agreeing to this TOS, the Affiliate Operator is also agreeing to each brand’s TOS they are advertising for.

                </p>
                <h4>CONFIDENTIALITY</h4>
                <p>
                    All confidential information, including, but not limited to, any business, technical, financial, and customer information, disclosed by one party to the other during negotiation or the effective term of this Agreement which is marked "Confidential," will remain the sole property of the disclosing party, and each party will keep in confidence and not use or disclose such proprietary information of the other party without express written permission of the disclosing party.

                </p> 
                <h4>GOVERNING LAW/JURISDICTION
                </h4>

                <p>
These terms and the use of the Site shall be governed by, and construed in accordance with, the laws of the State of Massachusetts without regard to the principles of conflicts of law. Jurisdiction and venue for any action, whether arbitration or otherwise shall be solely and exclusively in the County of Suffolk, State of Massachusetts and by entering this Site, you agree to in personam jurisdiction.


                </p>

                <p>This Agreement and any dispute or claim arising out of or in connection with them shall be governed by and construed and interpreted in accordance with the laws of the the United States and the parties submit to the jurisdiction of the state courts located in the the United States. Nothing in this clause shall limit Zeiger’ right to take proceedings against Publisher in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction.
                </p>
                <p>Publisher hereby irrevocably waives any objection which Publisher may now or hereafter have to the laying of the venue of any suit, action or proceeding arising out of or relating to this Agreement in the above mentioned jurisdictions, and hereby further irrevocably waive any claim that such jurisdictions are not convenient forums for any suits, actions or proceedings.
                </p>

                <h4>DISCLAIMER</h4>
                <p>
                    Zeiger Inc does not guarantee that the operation of its site will be uninterrupted or error free, and will not be liable for the consequences of any interruptions or errors. Affiliate Operator represents and warrants that:
                </p>
                <p>
                    This Agreement has been duly and validly executed and constitutes a legal, valid, and binding obligation, enforceable in accordance with its terms; Affiliate Operator has the full right, power, and authority to enter into and be bound by the terms and conditions of this Agreement and to perform its obligations under this Agreement, without the approval or consent of any other party; Affiliate Operator has sufficient right, title, and interest in and to the rights granted in this Agreement; Affiliate Operator may not amend or waive any provision of this Agreement unless in writing and signed by both parties; This Agreement represents the entire agreement between Zeiger Inc and the Affiliate Operator, and shall supersede all prior agreements and communications of the parties, oral or written; The headings and titles contained in this Agreement are included for convenience only, and shall not limit or otherwise affect the terms of this Agreement; If any provision of this Agreement is held to be invalid or unenforceable, that provision shall be eliminated or limited to the minimum extent necessary such that the intent of the parties is effectuated, and the remainder of this agreement shall have full force and effect. 
                </p>
              
                <h4>ARBITRATION</h4>
                <p>
                    It is agreed that any conflicts arising out of or relating to these terms shall be solely and exclusively determined by binding arbitration. The neutral Arbitrator shall have the authority to entertain and determine all matters and disputes between the Parties, including the issuance of preliminary and/or permanent injunction, as well as awarding attorney’s fees (including in-house fees) and costs to the prevailing Party. Accordingly, all disputes and claims arising from this Agreement shall be determined by an arbitration instituted solely and exclusively in the County of Suffolk, State of Massachusetts, before a single neutral Arbitrator through Alternative Dispute Resolution Services, Inc., or a comparable alternative dispute resolution company, which shall be selected by the Party who initially files the claim. In the event that the Parties cannot agree upon the designation of the Arbitrator, the Arbitrator shall be a retired judge or justice of the Massachusetts Superior Court or the United States District Court and shall be designated by the selected arbitration association. All fees for said arbitration shall be advanced and paid equally between the Parties unless otherwise determined by the Arbitrator.

                </p>

                <h4>WAIVER OF RIGHTS TO COURT PROCEEDING</h4>
                <p>
                    You acknowledge that you are waving your right to resolve any dispute within the judicial system. Notwithstanding, upon a final ruling by an arbitrator as set forth above, either Party may seek to enforce a final judgment in any Court in the County of Suffolk, State of Massachusetts or the United States District Court, Central District of Massachusetts.

                </p>
                <h4>
                    TERMINATION & EFFECT

                </h4>
                <p>
                    Company may terminate access to the Site, with or without cause, at any time, and effective immediately. Company shall not be liable to you or any third party for termination. Should you object to any provision of the Terms or any subsequent modifications thereto, your only recourse is to immediately terminate use of the Site.
                </p>
                <h4>MISCELLANEOUS</h4>
 
                <p>Severability and Integration. This Agreement constitutes the entire agreement between Publisher and Zeiger and supersede all previous written or oral agreements. If any part of this Agreement is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect.
                </p>
               </div>
            </div>
            
								</div>
							</div>
							 
							<script
         src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js"
         type="text/javascript"
    
         ></script>
							<script
         src="./js/fa2.js"
         type="text/javascript"
         ></script>
						</body>
					</html>