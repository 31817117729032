<!DOCTYPE html>
<html>

<head>
	<title>full screen iframe</title>
	<style type="text/css">
		html {
			overflow: auto;
		}
		
		html,
		body,
		div,
		iframe {
			margin: 0px;
			padding: 0px;
			height: 100vh;
			border: none;
		}
		
		iframe {
			display: block;
			width: 100%;
			border: none;
			overflow-y: auto;
			overflow-x: hidden;
		}
	</style>
</head>

<body>
	<iframe src="https://zapruder-landing.herokuapp.com/"
			frameborder="0"
			marginheight="0"
			marginwidth="0"
			width="100%"
			height="100vh"
			scrolling="auto">
</iframe>

 </body>

</html>
