import { Observable, of, BehaviorSubject } from "rxjs";

import { Injectable, Inject, forwardRef } from "@angular/core";
import { Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { Timestamp } from "@google-cloud/firestore";

import * as LZString from "lz-string";

export interface MonthlyTransactionContainer {
  monthlyTransactions: MonthlyTransactions[];
}

export interface ZapruderDeal {
  site: string;
  rate: number;
  name: string;
  referral: boolean;
  id: string;
  customerId: string;
}

export interface ZapruderDealResponse {
  deals: ZapruderDeal[];
  eligibleForUpgrade: boolean;
}

export interface MonthlyTransactions {
  transactions: Transaction[];
  date: Date;
  type: TransactionType;
}

export interface StratosTransaction {
  id: string;
  amount: number;
  date: Date;
  type: TransactionType;
  status: string;
  details: string;
  requestedDate: Date;
  notes: string;
  // origin: TransactionOrigin?
  zapruderUser: ZapruderUser;
}

export interface Transaction {
  status: TransactionType;
  amount: number;
  refAmount: number;
  userId: string;
  time: Date;
  user: User;
  uid: string;
  tx_id: string;
  address: string;
  notes: string;
  requested: boolean;
  requestedDate: Date;
}

export interface SettingsInfo {
  name: string;
  country_of_citizenship: string;
  mailingAddress: MailingAddress;
  residentialAddress: MailingAddress;
  tin: string;
  accepted_terms: boolean;
  mailing_residential_same: boolean;
}

export interface MailingAddress {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface User {
  name: string;
  id: string;
  email: string;
}

export interface Player {
  customerId: string;
  name: string;
  payload: string;
  id: string;
  zapruderId: string;
  site: string;
  signupDate: Date;
}

export interface PlayerMonthContainer {
  month: Date;
  uid: string;
  playerMonths: PlayerMonth[];
}

export interface PlayerMonth {
  days: Day[];
  month: Date;
  player: GetPlayer; // TODO: GetPlayer
  netRevenue: number;
  netRevenuePoker: number;
  netRevenueCasino: number;
  netRevenueSportsbook: number;
  casinoHandle: number;
  sportsbookHandle: number;
  deposits: number;
  fees: number;
  playerOwed: number;
}

export interface PlayerMonthResponse {
  playerMonthContainers: PlayerMonthContainer[];
}

export interface ZapruderMonthPayload {
  referrals: Month[];
  player: Month[];
  referralMonth: PlayerMonthResponse;
}
export enum TransactionType {
  Pending = "Pending",
  Paid = "Paid",
  Posted = "Posted",
  Requested = "Requested",
  Hold = "Hold",
}

export interface ZapruderUser {
  id: string;
  name: string;
  email: string;
  discord: string;
  createdAt: Date;
  customerIds: [{}];
  verificationStatus: string;
  international: boolean;
}

export interface Me {
  email: string;
  id: string;
  admin: boolean;
  discord?: string;
  phoneNumber?: string;
  consent?: boolean;
  name: string;
  createdAt: Date;
  customerIds: CustomerId[];
  address?: string;
  autopayEnabled: boolean;
  verificationStatus: string;
  international: boolean;
}

export interface CustomerId {
  referral: boolean;
  id: string;
  customerId: string;
  percent: number;
  name: string;
}

export interface Month {
  days: Day[];
  month: Date;
  deposits: number;
  netRevenue: number;
  netRevenuePoker: number;
  netRevenueCasino: number;
  netRevenueSportsbook: number;
  casinoHandle: number;
  sportsbookHandle: number;
  fees: number;
  playerOwed?: number;
}

export interface Day {
  date: Date;
  rows: GetRow[];
  netRevenue: number;
  netRevenuePoker: number;
  netRevenueCasino: number;
  netRevenueSportsbook: number;
  casinoHandle: number;
  sportsbookHandle: number;
  deposits: number;
  fees: number;
  playerOwed?: number;
}

export interface GetPlayer {
  id: string;
  name: string;
  site: string;
  //    let payload: String?
  //    let site: String?
  //    let name: String?
  //    let signupDate: Date?
  //    let totalDeposits: Double?
}

export interface GetRow {
  id: String;
  date: Date;
  channel: String;
  payPeriod: Date;
  customerGroup: String;
  customer: String;
  deposits: number;
  casinoHandle: number;
  casinoNGR: number;
  sportsbookHandle: number;
  sportsbookNGR: number;
  pokerNGR: number;
  totalNGR: number;
  income: number;
  fees: number;
  player: GetPlayer;
  playerOwed: number;
}

export interface Row {
  userId: string;
  amount: number;
  payPeriod: Date;
  day: Date;
  player: Player;
  deposit: number;
  site: string;
  income: number;
  ngr: number;
  transactionFees: number;
  marketingFees: number;
  bonus: number;
  casinoHandle: number;
  casinoNGR: number;
  totalNGR: number;
}

export interface AnalyticsMonth {
  date: Date;
  totalPayments: Number;
  totalIncome: Number;
  totalRaked: Number;
  earnings: Number;
  payments: Payment[];
  income: Income[];
}

export interface Payment {
  date: Date;
  amount: number;
  name: String;
  uid: String;
  type: String;
  raked: number;
  netRaked: number;
  paymentRows: PaymentRow[];
}

export interface PaymentRow {
  name: String;
  uid: String;
  raked: Number;
  fees: Number;
  netRaked: Number;
  earned: Number;
  site: String;
  percent: Number;
}

export interface Income {
  date: Date;
  amount: Number;
  site: String;
}

export interface CurrentUser {
  id: string;
  name: string;
  address: string;
  autopayEnabled: boolean;
  admin: boolean;
  synced: boolean;
  email: string;
}

export interface Cache {
  currentUser: CurrentUser;
}

export interface Leaderboard {
  name: string;
  rows: LeaderboardRow[];
  month: Date;
  id: number;
  type: string;
}

export interface LeaderboardContainer {
  chases: Leaderboard[];
  races: Leaderboard[];
  month: Date;
}

export interface LeaderboardRow {
  position: number;
  points: number;
  rake: number;
  prize: number;
  username: string;
}

export interface Token {
  id: string;
  access_token: string;
  id_token: string;
}

export interface Leaderboard {
  rows: LeaderboardRow[];
}

export interface LeaderboardRow {
  screenname: string;
  rake: number;
  prize: number;
  rank: number;
  level: number;
}

export interface VGSkin {
  skin_name: string;
  skin_name_intern: string;
  skin_id: number;
  network_name: string;
}

@Injectable({
  providedIn: "root",
})
export class ApiService {
  STRATOS_URL_ROOT = `https://api.stratosnetwork.com`;

  // STRATOS_URL_ROOT = `http://localhost:8001`
  _cache: Cache = {
    currentUser: undefined,
  };

  public get cache() {
    return this._cache;
  }

  public set cache(val) {
    this._cache = val;
  }

  currentUserSubject = new BehaviorSubject<CurrentUser>(undefined);

  getCurrentUser() {
    this.currentUserSubject.next(this.currentUser);
  }

  private datastore = new Subject<Cache>();
  datastoreSource$ = this.datastore.asObservable();

  private isLoadingSubject = new Subject<Boolean>();
  isLoadingSource$ = this.isLoadingSubject.asObservable();

  _isLoading: Boolean = false;
  public get isLoading() {
    return this._isLoading;
  }

  public set isLoading(val) {
    this._isLoading = val;
    this.isLoadingSubject.next(val);
  }

  getLoading() {
    this.isLoadingSubject.next(this.isLoading);
  }

  getDatastore() {
    this.datastore.next(this.cache);
  }

  setDatastore() {
    setTimeout(() => {
      const compressed = LZString.compress(JSON.stringify(this.cache));
      localStorage.setItem("stratos-cache", compressed);
    }, 1);

    this.datastore.next(this.cache);
  }

  clearCache() {
    localStorage.removeItem("stratos-cache");
  }

  updateZapruderAccount(id, body) {
    const url = `${this.STRATOS_URL_ROOT}/shared/updateUser/${id}`;
    return this.http.post(url, body).toPromise();
  }

  getSkins() {
    const url = `${this.STRATOS_URL_ROOT}/stratos/skins`;

    return new Promise<VGSkin[]>((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then((payload) => {
          const resp = JSON.parse(JSON.stringify(payload));
          console.log(resp);
          resolve(resp);
        })
        .catch(reject);
    });
  }

  getIP() {
    const url = `https://api.ipify.org?format=json&callback=IP`;
    return new Promise<string>((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          const ip = res["ip"];
          resolve(ip);
        })
        .catch(reject);
    });
  }

  logIP() {
    if (this.currentUser === undefined) {
      return;
    }
    return new Promise<{}>(async (resolve, reject) => {
      const ip = await this.getIP();

      const url = `${this.STRATOS_URL_ROOT}/zapruder/log-ip`;
      const body = {
        userId: this.currentUser.id,
        ipAddress: ip,
      };

      this.http
        .post(url, body)
        .toPromise()
        .then(() => {
          resolve({});
        });
    });
  }

  currentUser: CurrentUser;
  constructor(
    @Inject(forwardRef(() => AngularFirestore)) public db: AngularFirestore,
    private http: HttpClient,
    public firebaseAuth: AngularFireAuth,
    private router: Router
  ) {
    console.log("api init");
    setTimeout(() => {
      const rawCache = localStorage.getItem("stratos-cache");
      if (rawCache !== null) {
        this.cache = JSON.parse(LZString.decompress(rawCache));
      }
    }, 1);

    setTimeout(() => {
      this.logIP();
    }, 5000);

    this.firebaseAuth.authState.subscribe(async (user) => {
      if (user) {
        var id = user.uid;
        var refresh_token = user.refreshToken;
        const token = await this.getToken(refresh_token, user.uid);
        localStorage.setItem("zap-token", token.id);
        // const id = 'J6pEwyZLPHZown6Hymxajcjzx833'
        //  id = '5G5BJFtM6qMt0lAixtbr7jHskgr1'

        const me = await this.getMe(id);
        var admin = false;
        if (
          me.id === "UJUkYEyhsFNMjLO9Nt34u4jgh8r1" ||
          me.id === "T75ZrDjddcUHo8eIc7Mo8T1QCSX2" ||
          me.id === "cpVTkvTjp5dKJ8zAazIhPVpoM6F3" ||
          me.id === "cEYWt1GlLqQs0kwjZX0QPVnbjqm2"
        ) {
          admin = true;
        }
        // id = 'cKvhGV5C3KeNMDzM3ztgQgSf7ln1'
        this.currentUser = {
          autopayEnabled: false,
          name: me.name,
          admin: admin,
          id: id,
          address: "",
          synced: false,
          email: user.email,
        };
        this.currentUser.synced = me.customerIds.length > 0 ? true : false;
        this.cache.currentUser = this.currentUser;

        this.currentUserSubject.next(this.currentUser);
      } else {
        this.currentUser = undefined;
        this.currentUserSubject.next(this.currentUser);
      }
    });
  }

  getToken(refresh_token: string, user_id: string) {
    const url = `${this.STRATOS_URL_ROOT}/auth/zapruder-token`;
    const body = {
      refresh_token: refresh_token,
      user_id: user_id,
    };
    return new Promise<Token>((resolve, reject) => {
      this.http
        .post(url, body)
        .toPromise()
        .then((res) => {
          const token = JSON.parse(JSON.stringify(res));
          resolve(token);
        })
        .catch(reject);
    });
  }

  getZapruderUser(id: string) {
    const url = `${this.STRATOS_URL_ROOT}/shared/players/${id}`;
    return new Promise<ZapruderUser>((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          const token = JSON.parse(JSON.stringify(res));
          resolve(token);
        })
        .catch(reject);
    });
  }

  getZapruderUsers() {
    const url = `${this.STRATOS_URL_ROOT}/shared/players`;
    return new Promise<ZapruderUser[]>((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          const token = JSON.parse(JSON.stringify(res));
          resolve(token);
        })
        .catch(reject);
    });
  }

  getSpecificInfo(id: string) {
    const url = `${this.STRATOS_URL_ROOT}/zapruder/specific_info/${id}`;

    return new Promise<SettingsInfo>((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          const info = JSON.parse(JSON.stringify(res));
          resolve(info);
        })
        .catch(reject);
    });
  }

  saveSpecificInfo(id: string, info: SettingsInfo) {
    const url = `${this.STRATOS_URL_ROOT}/zapruder/specific_info/${id}`;

    return new Promise<SettingsInfo>((resolve, reject) => {
      this.http
        .post(url, info)
        .toPromise()
        .then((res) => {
          const info = JSON.parse(JSON.stringify(res));
          resolve(info);
        })
        .catch(reject);
    });
  }

  getInfo() {
    const url = `${this.STRATOS_URL_ROOT}/zapruder/info`;

    return new Promise<SettingsInfo>((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          const info = JSON.parse(JSON.stringify(res));
          resolve(info);
        })
        .catch(reject);
    });
  }

  saveInfo(info: SettingsInfo) {
    const url = `${this.STRATOS_URL_ROOT}/zapruder/info`;

    return new Promise<SettingsInfo>((resolve, reject) => {
      this.http
        .post(url, info)
        .toPromise()
        .then((res) => {
          const info = JSON.parse(JSON.stringify(res));
          resolve(info);
        })
        .catch(reject);
    });
  }

  getAllAccounts() {
    const url = `${this.STRATOS_URL_ROOT}/zapruder/allAccounts`;

    return new Promise<Player[]>((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          const token = JSON.parse(JSON.stringify(res));
          resolve(token);
        })
        .catch(reject);
    });
  }

  getUnsyncedAccounts() {
    const url = `${this.STRATOS_URL_ROOT}/zapruder/unlinkedAccounts`;

    return new Promise<Player[]>((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          const token = JSON.parse(JSON.stringify(res));
          resolve(token);
        })
        .catch(reject);
    });
  }

  logout() {
    this.firebaseAuth.signOut().then(() => {
      localStorage.removeItem("stratos-cache");
      localStorage.removeItem("zap-token");
      this.router.navigate(["/auth/login"]);
    });
  }

  login(email: string, password: string) {
    return this.firebaseAuth.signInWithEmailAndPassword(email, password);
  }

  retagRequest(email: string, handle: string, notes: string) {
    const url = `${this.STRATOS_URL_ROOT}/shared/retagRequest`;
    return new Promise<{}>((resolve, reject) => {
      const body = {
        email: email,
        acr: handle,
        notes: notes,
      };

      this.http
        .post(url, body)
        .toPromise()
        .then((payload) => {
          resolve({});
        })
        .catch(reject);
    });
  }

  getZapruderReport(id: string) {
    const url = `${this.STRATOS_URL_ROOT}/shared/payments/${id}`;

    return new Promise<AnalyticsMonth[]>((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          var months: AnalyticsMonth[] = JSON.parse(JSON.stringify(res));
          months = months.filter((m) => m.payments.length > 0);
          console.log(months);
          resolve(months);
        })
        .catch(reject);
    });
  }

  getBTCFee(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      const url = `https://api.blockchain.info/mempool/fees`;
      const url2 = `https://blockchain.info/ticker`;

      this.http
        .get(url)
        .toPromise()
        .then((payload) => {
          const satoshi_byte = payload["regular"];

          this.http
            .get(url2)
            .toPromise()
            .then((payload2) => {
              const usd_satoshi = payload2["USD"]["15m"] / 100000000;
              const fee = usd_satoshi * satoshi_byte * 500 * (28.77 / 67.69);

              console.log(`${fee}`);
              resolve(fee);
            });
        });
    });
  }

  getRows(id: string) {
    return new Promise<ZapruderMonthPayload>((resolve, reject) => {
      if (id === undefined) {
        reject();
        return;
      }
      const url = `${this.STRATOS_URL_ROOT}/shared/v2/rows/${id}`;

      const body = {};

      this.http
        .post(url, body)
        .toPromise()
        .then((payload) => {
          const rows: ZapruderMonthPayload = JSON.parse(
            JSON.stringify(payload)
          );
          // console.log(rows)
          resolve(rows);
        });
    });
  }

  getOrderedTransactions() {
    return new Promise<Transaction[]>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/shared/orderedTransactions`;
      this.http
        .get(url)
        .toPromise()
        .then((payload) => {
          const tx: Transaction[] = JSON.parse(JSON.stringify(payload));
          console.log(tx);
          resolve(tx);
        })
        .catch(reject);
    });
  }

  getAllTransactions() {
    return new Promise<MonthlyTransactionContainer[]>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/shared/allTransactions`;
      this.http
        .get(url)
        .toPromise()
        .then((payload) => {
          const tx: MonthlyTransactionContainer[] = JSON.parse(
            JSON.stringify(payload)
          );
          console.log(payload);
          resolve(tx);
        });
    });
  }

  getACRLeaderboard() {
    return new Promise<Leaderboard>((resolve, reject) => {
      const url = `https://universal-poker-api.herokuapp.com/lb`;
      this.http
        .get(url)
        .toPromise()
        .then((payload) => {
          const tx: Leaderboard = JSON.parse(JSON.stringify(payload));
          resolve(tx);
        });
    });
  }

  getTransactions(id?: string) {
    return new Promise<Transaction[]>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/shared/transactions/${id}`;
      console.log(url);
      this.http
        .get(url)
        .toPromise()
        .then((payload) => {
          const tx: Transaction[] = JSON.parse(JSON.stringify(payload));

          resolve(tx);
        });
    });
  }

  getTransactionsLeaderboard(id?: string) {
    return new Promise<Transaction[]>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/shared/transactions/leaderboards/${id}`;
      console.log(url);
      this.http
        .get(url)
        .toPromise()
        .then((payload) => {
          const tx: Transaction[] = JSON.parse(JSON.stringify(payload));

          resolve(tx);
        });
    });
  }
  //7QLU7LpsNDgQOeggC6SAcD0MiqU2

  getMe(id: string) {
    return new Promise<Me>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/shared/v2/users/${id}`;

      this.http
        .get(url)
        .toPromise()
        .then((payload) => {
          const me: Me = JSON.parse(JSON.stringify(payload));
          me.customerIds = me.customerIds.sort((x, y) =>
            x.percent < y.percent ? 1 : -1
          );
          resolve(me);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // TODO: fix this!!
  makePayoutRequest(transaction: Transaction, address: string) {
    return new Promise<{}>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/zapruder/updateTransaction`;

      const body = {
        id: transaction.uid,
        status: transaction.status,
        address: address,
      };

      this.http
        .post(url, body)
        .toPromise()
        .then((payload) => {
          resolve({});
        })
        .catch(reject);
    });
  }

  formattedDate(date: Date): string {
    return `${date.getMonth() + 1}/1/${date.getFullYear()}`;
  }

  convertToMonthlyTransactionsForType(
    transactions: Transaction[],
    type: TransactionType,
    requested: boolean = false
  ): MonthlyTransactionContainer {
    const filteredT = transactions.filter((t) => t.status === type);
    var dic = {};
    filteredT.map((p) => {
      if (dic[this.formattedDate(p.time)] === undefined) {
        dic[this.formattedDate(p.time)] = [p];
      } else {
        dic[this.formattedDate(p.time)].push(p);
      }
    });

    const monthly: MonthlyTransactions[] = Object.keys(dic)
      .map((key) => {
        return {
          date: new Date(key),
          transactions: dic[key],
          type: requested ? TransactionType.Requested : type,
        };
      })
      .map((m) => {
        const sorted = m.transactions.sort((a, b) => {
          if (a.amount < b.amount) {
            return 1;
          } else {
            return -1;
          }
        });
        console.log(m.transactions);
        return {
          date: m.date,
          transactions: sorted,
          type: m.type,
        };
      });

    return {
      monthlyTransactions: monthly,
    };
  }

  updateTransaction(transaction: Transaction) {
    const url = `${this.STRATOS_URL_ROOT}/zapruder/updateTransaction`;

    const body = {
      id: transaction.uid,
      status: transaction.status,
    };

    this.http
      .post(url, body)
      .toPromise()
      .then((payload) => {})
      .catch(console.log);
  }

  async getDeals(id: string) {
    return new Promise<ZapruderDealResponse>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/shared/v2/deals/${id}`;

      this.http
        .get(url)
        .toPromise()
        .then((payload) => {
          const deals: ZapruderDealResponse = JSON.parse(
            JSON.stringify(payload)
          );
          console.log("DEALS");
          console.log(deals);
          resolve(deals);
          // resolve(deals.sort((x, y) => {
          //     if (x.rate < y.rate) {
          //         return 1
          //     } else {
          //         return -1
          //     }
          // }))
        })
        .catch((err) => {
          console.log("logging some error");
          console.log(err);
        });
    });
  }

  async getAllZapruderUsers() {
    return new Promise<User[]>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/shared/v2/allUsers`;

      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }),
      };
      const body = {};

      this.http
        .get(url, httpOptions)
        .toPromise()
        .then((payload) => {
          const users: User[] = JSON.parse(JSON.stringify(payload));
          resolve(users);
          console.log(users);
        })
        .catch((err) => {
          console.log("logging some error");
          console.log(err);
        });
    });
  }

  async getLeaderboardsFiltered(id: string) {
    return new Promise<LeaderboardContainer[]>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/shared/leaderboards/ranking/${id}`;

      this.http
        .get(url)
        .toPromise()
        .then((payload) => {
          const leaderboards: LeaderboardContainer[] = JSON.parse(
            JSON.stringify(payload)
          );

          resolve(leaderboards);
        })
        .catch((err) => {
          console.log("logging some error");
          console.log(err);
        });
    });
  }

  async getLeaderboards() {
    return new Promise<LeaderboardContainer[]>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/shared/leaderboardsAll`;

      this.http
        .get(url)
        .toPromise()
        .then((payload) => {
          const leaderboards: LeaderboardContainer[] = JSON.parse(
            JSON.stringify(payload)
          );
          resolve(leaderboards);
        })
        .catch((err) => {
          console.log("logging some error");
          console.log(err);
        });
    });
  }

  async updateUserSettings(body: {
    id: string;
    discord: string;
    consent: boolean;
    phoneNumber: string;
  }) {
    return new Promise<{}>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/zapruder/updateUserSettings`;

      this.http
        .post(url, body)
        .toPromise()
        .then((payload) => {
          resolve({});
        })
        .catch(reject);
    });
  }

  async updateUserSettingsBTC(body: {
    id: string;
    address: string;
    autopayEnabled: boolean;
  }) {
    return new Promise<{}>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/zapruder/updateUserSettings`;

      this.http
        .post(url, body)
        .toPromise()
        .then((payload) => {
          resolve({});
        })
        .catch(reject);
    });
  }

  createUser(uid: string, discord: string, name: string, email: string) {
    return new Promise<{}>((resolve, reject) => {
      const url = `${this.STRATOS_URL_ROOT}/shared/createUser`;

      const body = {
        uid: uid,
        discord: discord,
        name: name,
        email: email,
      };
      this.http
        .post(url, body)
        .toPromise()
        .then((payload) => {
          resolve({});
        })
        .catch(reject);
    });
  }

  submitAccount(payload: {
    skinId: string;
    username: string;
    zapruderId: string;
    isRetag: boolean;
    email: string;
  }) {
    const url = `${this.STRATOS_URL_ROOT}/stratos/submitAccount`;

    console.log(payload);
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .post(url, payload)
        .toPromise()
        .then((res) => {
          resolve(true);
        })
        .catch(reject);
    });
  }
}

// b47978f5-3e32-4c19-937b-597e553eb147

// curl -n -X DELETE https://api.heroku.com/apps/stratos-vapor/dynos/worker_bulk \n -H "Content-Type: application/json" \n -H "Accept: application/vnd.heroku+json; version=3" \n -H "Authorization: Bearer b47978f5-3e32-4c19-937b-597e553eb147"
