import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private firebaseAuth: AngularFireAuth) {}

  canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      this.firebaseAuth.authState.subscribe((user) => {
        if (user) {
          resolve(true);
       } else {
          this.router.navigate(['/landing']);
          resolve(false);
        }
      })
    })
  }
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   const currentUser = this.authService.currentUserValue;
  //   if (currentUser) {
  //     // logged in so return true
  //     // this.authService.logout();

  //     return true;
  //   }

  //   // not logged in so redirect to login page with the return url
  //   this.authService.logout();
  //   return false;
  // }
}
