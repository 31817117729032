import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService, CurrentUser } from 'src/app/services/api.service';

@Component({
    selector: 'app-terms',
    templateUrl: './terms-of-service.component.html',
    styleUrls: ['./terms-of-service.component.scss'],
})
export class TermsOfServiceComponent implements OnInit {
    ngOnInit() { }

    constructor(private api: ApiService, private cd: ChangeDetectorRef) {} 
}