import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Renderer2,
  ElementRef,
} from "@angular/core";
import { ApiService, CurrentUser } from "src/app/services/api.service";

@Component({
  selector: "app-typeform",
  templateUrl: "./typeform.component.html",
  styleUrls: ["./typeform.component.scss"],
})
export class TypeformComponent implements OnInit {
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    let script = this.renderer.createElement("script");
    script.type = `text/javascript`;
    script.src = `//embed.typeform.com/next/embed.js`;
    script.onload = () => {
      // Script has loaded
    };
    this.renderer.appendChild(this.el.nativeElement, script);
  }
}
