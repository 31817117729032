import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./modules/auth/_services/auth.guard";
import { LandingComponent } from "./pages/landing/landing.component";
import { TermsOfServiceComponent } from "./pages/terms/terms-of-service.component";
import { TypeformComponent } from "./pages/typeform/typeform.component";

export const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "deals",
    loadChildren: () =>
      import("./modules/deals/deals.module").then((m) => m.DealsModule),
  },
  {
    path: "error",
    loadChildren: () =>
      import("./modules/errors/errors.module").then((m) => m.ErrorsModule),
  },
  {
    path: "landing",
    component: LandingComponent,
  },
  {
    path: "form",
    component: TypeformComponent,
  },
  {
    path: "terms",
    component: TermsOfServiceComponent,
  },
  {
    path: "",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/layout.module").then((m) => m.LayoutModule),
  },
  { path: "**", redirectTo: "landing" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
